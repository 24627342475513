<template>
  <div>
    <a-form :form="form" @submit="handleSubmit">
      <a-row :gutter="20">
        <a-col :span="12">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'"> Nome do funil </label>
            <a-input
              class="travel-input"
              placeholder="Escreva"
              v-decorator="[
                `name`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'"> Responsável pelo funil </label>
            <a-select
              class="travel-input"
              placeholder="Selecione"
              optionFilterProp="txt"
              v-decorator="[
                `funnel_user_id`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              style="width: 100%"
            >
              <a-select-option
                v-for="(item, index) of user.list"
                :key="index"
                :value="item.id"
                :txt="item.first_name"
              >
                {{ item.id }} - {{ item.first_name }} {{ item.last_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <!-- <a-col :span="12">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'"> Visibilidade </label>
            <a-select
              class="travel-input"
              placeholder="Selecione"
              optionFilterProp="txt"
              v-decorator="[
                `funnel_user_id`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              style="width: 100%"
            >
              <a-select-option
                v-for="(item, index) of user.list"
                :key="index"
                :value="item.id"
                :txt="item.first_name"
              >
                {{ item.id }} - {{ item.first_name }} {{ item.last_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col> -->

        <a-col class="mt-10" :span="24">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'" style="top: -46px">Descrição</label>
            <a-input
              class="travel-textarea"
              placeholder="Escreva uma  descrição para o funil"
              type="textarea"
              v-decorator="[`description`]"
            >
            </a-input>
          </a-form-item>
        </a-col>

        <a-col class="a-center mt-20" :span="24">
          <a-button
            type="primary"
            html-type="submit"
            :loading="loadingUpdateFunnel"
          >
            Atualizar Funil
          </a-button>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import userMixins from "@/mixins/user/userMixins.js";
import opportunityFunnelsMixins from "../mixins/opportunityFunnelsMixins";

export default {
  name: "EditFunnelModal",
  props: {
    editId: Number,
  },
  mixins: [userMixins, opportunityFunnelsMixins],
  data() {
    return {
      form: this.$form.createForm(this),
      loadingUpdateFunnel: false,
    };
  },
  mounted() {
    this.user.filters.status.selected = "1";
    this.user.pagination.perPage = 500;
    this.getUsers();
    this.getOpportunityFunnel(this.editId);
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.id = this.editId;
        console.log(err, values);
        if (!err) {
          this.loadingUpdateFunnel = true;

          this.$http
            .post("/opportunity-funnels/update", values)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.$emit("loadFunnelsList");
              this.loadingUpdateFunnel = false;

              this.$http.post("/log/create", {
                user_id: this.$store.state.userData.id,
                module_id: this.editId,
                module: "opportunity-funnels",
                action: "update-opportunity-funnel",
                description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} atualizou o funil ID ${this.editId}.`,
              });
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loadingUpdateFunnel = false;
            });
        }
      });
    },
  },
};
</script>
