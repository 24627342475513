var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Nome do funil ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `name`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `name`,\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",attrs:{"placeholder":"Escreva"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Responsável pelo funil ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `funnel_user_id`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `funnel_user_id`,\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt"}},_vm._l((_vm.user.list),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.first_name}},[_vm._v("\n              "+_vm._s(item.id)+" - "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+"\n            ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{staticClass:"mt-10",attrs:{"span":24}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',staticStyle:{"top":"-46px"}},[_vm._v("Descrição")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([`description`]),expression:"[`description`]"}],staticClass:"travel-textarea",attrs:{"placeholder":"Escreva uma  descrição para o funil","type":"textarea"}})],1)],1),_vm._v(" "),_c('a-col',{staticClass:"a-center mt-20",attrs:{"span":24}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loadingUpdateFunnel}},[_vm._v("\n          Atualizar Funil\n        ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }