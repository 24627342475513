export default {
  data() {
    return {
      opportunityFunnels: {
        loading: false,
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          totalPages: 0,
        },
        details: {},
        list: [],
        filters: {
          users: {
            list: [],
            selected: [],
          },
          visibility: {
            list: [
              {
                label: "Ativo",
                value: "Ativo",
              },
              {
                label: "Desativado",
                value: "Desativado",
              },
            ],
            selected: [],
          },
          period: {
            selected: [],
          },
          searchTerm: "",
          order: "desc",
          orderBy: "created",
        },
      },
      funnelDefaultValue: "",
    };
  },
  methods: {
    changeOpportunityFunnelsPage(current) {
      this.opportunityFunnels.pagination.page = current;
      this.getOpportunityFunnels();
    },
    changeOpportunityFunnelsPageSize(current, pageSize) {
      this.opportunityFunnels.pagination.page = current;
      this.opportunityFunnels.pagination.perPage = pageSize;
      this.getOpportunityFunnels();
    },
    opportunityFunnelsTableChange(pagination, filters, sorter) {
      filters;
      pagination;
      this.opportunityFunnels.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.opportunityFunnels.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getOpportunityFunnels();
    },
    getOpportunityFunnel(id) {
      // load
      this.$http
        .post(`/opportunity-funnels/details?id=${id}`)
        .then(({ data }) => {
          this.opportunityFunnels.details = data;

          this.form.setFieldsValue(data.raw);
        })
        .catch(({ response }) => {
          response;
          this.$message.error(response.data.message);
        });
    },
    getOpportunityFunnels(samePage) {
      let filters = "";

      this.opportunityFunnels.loading = true;

      if (this.opportunityFunnels.filters.visibility.selected.length > 0) {
        filters += `&visibility=${this.opportunityFunnels.filters.visibility.selected}`;
      }

      if (this.opportunityFunnels.filters.users.selected.length > 0) {
        filters += `&user_id=${this.opportunityFunnels.filters.users.selected}`;
      }

      filters += `&order=${this.opportunityFunnels.filters.order}&order-by=${this.opportunityFunnels.filters.orderBy}`;

      this.$http
        .get(
          `/opportunity-funnels/list?page=${this.opportunityFunnels.pagination.page}&per_page=${this.opportunityFunnels.pagination.perPage}${filters}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.opportunityFunnels.pagination.page = 1;
          }

          this.funnelDefaultValue = data.data[0].id;

          this.opportunityFunnels.pagination.total = data.meta.total;
          this.opportunityFunnels.pagination.totalPages = data.meta.total_pages;
          this.opportunityFunnels.list = data.data;
          this.opportunityFunnels.loading = false;
        })
        .catch(({ response }) => {
          this.opportunityFunnels.loading = false;

          this.funnelDefaultValue = "";

          this.opportunityFunnels.list = response.data.data;
          this.opportunityFunnels.pagination.total = response.data.meta.total;
          this.opportunityFunnels.pagination.totalPages =
            response.data.meta.total_pages;

        });
    },
  },
};
